import { isError } from 'lodash';

// 에러 형 변환
// eslint-disable-next-line import/prefer-default-export
export function convertError(error) {
  if (isError(error)) {
    if (error && error.response && error.response.data) {
      const { data, status } = error.response;

      return { status, message: data.m || data.message, code: data.c || data.code };
    }

    const errorString = error.toString();
    let errorMessagePrefix = '';

    if (error.response && error.response.status === 500) {
      errorMessagePrefix = '[500] ';
    } else if (error.code === 'ECONNABORTED') {
      errorMessagePrefix = '[Timeout] ';
    } else if (errorString.indexOf('Network Error') > -1) {
      errorMessagePrefix = '[Network] ';
    } else if (errorString.indexOf('TypeError') > -1) {
      errorMessagePrefix = '[TypeError] ';
    }

    return { message: `${errorMessagePrefix}네트워크가 불안정합니다.` };
  }
  return error;
}
