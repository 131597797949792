import { createAction, handleActions } from 'redux-actions';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import produce from 'immer';
import getConfig from 'next/config';

import { executeWeb2App } from '~/utils/postMessage';

import fetcher from '~/api/lib/fetcher';
import { getV2AuthHeaders } from '~/utils/common';
import { HTTP_STATUS_CODES } from '~/utils/api';

const { publicRuntimeConfig } = getConfig();
const { V2_API } = publicRuntimeConfig;

// Actions
const SET_APP_VERSION = 'SET_APP_VERSION';
const SET_IS_WEBVIEW = 'SET_IS_WEBVIEW';
const SET_WASHID = 'SET_WASHID';
const SET_IS_USE_PLAN = 'SET_IS_USE_PLAN';
const SET_IS_DESKTOP_WEB = 'SET_IS_DESKTOP_WEB';
const SET_IS_ENTER_USE_TAB = 'SET_IS_ENTER_USE_TAB';
const SET_IS_ENTER_BY_COLLECT = 'SET_IS_ENTER_BY_COLLECT';
const USER_SUMMARY_INFO_REQUEST = 'USER_SUMMARY_INFO_REQUEST';
const USER_SUMMARY_INFO_SUCCESS = 'USER_SUMMARY_INFO_SUCCESS';
const CART_COUNT_FETCH_REQUEST = 'CART_COUNT_FETCH_REQUEST';
const CART_COUNT_FETCH_SUCCESS = 'CART_COUNT_FETCH_SUCCESS';
const CART_COUNT_FETCH_FAILURE = 'CART_COUNT_FETCH_FAILURE';
const POSSIBLE_ORDER_FETCH_REQUEST = 'POSSIBLE_ORDER_FETCH_REQUEST';
const POSSIBLE_ORDER_FETCH_SUCCESS = 'POSSIBLE_ORDER_FETCH_SUCCESS';

const setAppVersion = createAction(SET_APP_VERSION);
const setIsWebview = createAction(SET_IS_WEBVIEW);
const setWashId = createAction(SET_WASHID);
const setIsUsePlan = createAction(SET_IS_USE_PLAN);
const setIsDesktopWeb = createAction(SET_IS_DESKTOP_WEB);
const setIsEnterUseTab = createAction(SET_IS_ENTER_USE_TAB);
const setIsEnterByCollect = createAction(SET_IS_ENTER_BY_COLLECT);
const userSummaryInfoRequest = createAction(USER_SUMMARY_INFO_REQUEST);
const userSummaryInfoSuccess = createAction(USER_SUMMARY_INFO_SUCCESS);
const cartCountFetchRequest = createAction(CART_COUNT_FETCH_REQUEST);
const cartCountFetchSuccess = createAction(CART_COUNT_FETCH_SUCCESS);
const cartCountFetchFailure = createAction(CART_COUNT_FETCH_FAILURE);
const possibleOrderFetchRequest = createAction(POSSIBLE_ORDER_FETCH_REQUEST);
const possibleOrderFetchSuccess = createAction(POSSIBLE_ORDER_FETCH_SUCCESS);

export {
  setAppVersion,
  setIsWebview,
  setWashId,
  setIsUsePlan,
  setIsDesktopWeb,
  setIsEnterUseTab,
  setIsEnterByCollect,
  userSummaryInfoRequest,
  cartCountFetchRequest,
  cartCountFetchSuccess,
  possibleOrderFetchRequest,
  possibleOrderFetchSuccess,
};

const initialState = {
  loading: false,
  appVersion: null,
  cartCount: 0,
  isWebview: false,
  washId: 0,
  isUsePlan: true,
  isDesktopWeb: false,
  isEnterUseTab: false,
  isEnterByCollect: false,
  isOrderAvailable: true,
  orderNotAvailableReason: null,
  error: null,
};

export const reducer = handleActions(
  {
    [SET_APP_VERSION]: (state, { payload: appVersion }: any) =>
      produce(state, (draft) => {
        draft.appVersion = appVersion;
      }),
    [SET_IS_WEBVIEW]: (state, { payload: isWebview }: any) =>
      produce(state, (draft) => {
        draft.isWebview = isWebview;
      }),
    [SET_WASHID]: (state, { payload: washId }: any) =>
      produce(state, (draft) => {
        draft.washId = Number(washId) || 0;
      }),
    [SET_IS_USE_PLAN]: (state, { payload: isUsePlanYn }: any) =>
      produce(state, (draft) => {
        draft.isUsePlan = isUsePlanYn;
      }),
    [SET_IS_DESKTOP_WEB]: (state, { payload: isDesktopWeb }: any) =>
      produce(state, (draft) => {
        draft.isDesktopWeb = isDesktopWeb;
      }),
    [SET_IS_ENTER_USE_TAB]: (state, { payload: isEnterUseTab }: any) =>
      produce(state, (draft) => {
        draft.isEnterUseTab = isEnterUseTab;
      }),
    [SET_IS_ENTER_BY_COLLECT]: (state, { payload: isEnterByCollect }: any) =>
      produce(state, (draft) => {
        draft.isEnterByCollect = isEnterByCollect;
      }),
    [USER_SUMMARY_INFO_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.error = null;
      }),
    [USER_SUMMARY_INFO_SUCCESS]: (state, { payload: { joinServiceYn, washId } }: any) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.isUsePlan = joinServiceYn === 'Y';
        draft.washId = washId || 0;
        draft.error = null;
      }),
    [CART_COUNT_FETCH_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.error = null;
      }),
    [CART_COUNT_FETCH_SUCCESS]: (state, { payload: cartCount }: any) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.cartCount = cartCount;
        draft.error = null;
      }),
    [POSSIBLE_ORDER_FETCH_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.error = null;
      }),
    [POSSIBLE_ORDER_FETCH_SUCCESS]: (state, { payload: { possibleOrderYn, reasonType } }: any) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.isOrderAvailable = possibleOrderYn === 'Y';
        draft.orderNotAvailableReason = reasonType;
        draft.error = null;
      }),
  },
  initialState
);

function* watchPossibleOrderFetchSaga() {
  const { headers } = yield select((state) => state.http);
  const { washId } = yield select((state) => state.store.common);

  if (washId === 0) {
    yield put(
      possibleOrderFetchSuccess({
        possibleOrderYn: 'N',
        reasonType: 'NO_WASH_ID',
      })
    );
    return;
  }

  try {
    const orderPossibleUrl = `${V2_API}/v2/products/order/possible?washId=${washId}`;
    const { data } = yield call(fetcher.get, orderPossibleUrl, {
      headers: {
        ...getV2AuthHeaders(headers),
      },
    });

    yield put(possibleOrderFetchSuccess(data.data));
  } catch (error) {
    yield put(
      possibleOrderFetchSuccess({
        possibleOrderYn: 'N',
        reasonType: null,
      })
    );
  }
}

function* watchUserSummaryInfoFetchSaga(action) {
  const { headers } = yield select((state) => state.http);

  try {
    const { data } = yield call(fetcher.get, `${V2_API}/v2/products/summary-info`, {
      headers: {
        ...getV2AuthHeaders(headers),
      },
    });

    yield put(
      userSummaryInfoSuccess({
        ...data.data,
        washId: action.payload ? Number(action.payload) : data.data.washId,
      })
    );
    yield* watchPossibleOrderFetchSaga();
  } catch (error) {
    const copiedError: any = error;

    if (copiedError.response) {
      const { response } = copiedError;
      let message = '';

      if (response.data) {
        message = response.data?.m || message;
      }

      if (response.status !== HTTP_STATUS_CODES.unauthorized) {
        yield call(executeWeb2App, {
          command: 'showAlert',
          values: {
            title: '서버 오류',
            message,
          },
        });
      }
    }
  }
}

function* watchCartCountFetchSaga() {
  const { headers } = yield select((state) => state.http);

  try {
    const cartCountUrl = `${V2_API}/v2/products/basket/count`;
    const result = yield call(fetcher.get, cartCountUrl, {
      headers: {
        ...getV2AuthHeaders(headers),
      },
    });
    yield put(cartCountFetchSuccess(result.data.d));
  } catch (error) {
    yield put(cartCountFetchFailure(error));
  }
}

export { watchPossibleOrderFetchSaga, watchUserSummaryInfoFetchSaga };

export const sagas = [
  takeLatest<any>(USER_SUMMARY_INFO_REQUEST, watchUserSummaryInfoFetchSaga),
  takeLatest<any>(CART_COUNT_FETCH_REQUEST, watchCartCountFetchSaga),
  takeLatest<any>(POSSIBLE_ORDER_FETCH_REQUEST, watchPossibleOrderFetchSaga),
];
